<template>
    <div>
        <div class="block">
            <div v-for="(item, n) in cmsLeft" :key="n">
                <div v-if="item.Type === 'breadcrumb'">
                    <breadcrumb :data="item" />
                </div>
                <div v-else-if="item.Type === 'accordian'">
                    <accordian-with-title :data="item.Data" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        Breadcrumb: () => import('@/components/breadcrumb/breadcrumb'),
        AccordianWithTitle: () => import('@/components/accordian/AccordianWithTitle')
    },
    data () {
        return {
            cmsLeft: []
        }
    },
    methods: {
        async init () {
            console.log('aaaaaaaaaaaaa faq')
            var response = await this.$baseApi.get('/faqdetail?Code=top_faq')
            console.log('faq response', response)
            this.cmsLeft = response.Left
        }
    },
    mounted () {
        this.init()
    }
}
</script>
